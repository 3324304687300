<template>
  <main>
    <section id="thank-you">
      <b-container class="ombre">
        <b-row align-h="center">
          <b-col
            lg="8"
            xl="9"
          >
            <h1 v-html="$t('merci.title')"></h1>
            <img
              :alt="$t('merci.card')"
              class="img-fluid rounded my-4"
              :src="`${$t('baseurl')}img/${$t('lang')}/thank-you.png`"
            />
            <div v-html="$t('merci.desc')"></div>
            <br />
            <div
              class="alert alert-warning"
              v-html="$t('merci.stop.md')"
            ></div>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <section id="share">
      <b-container class="ombre">
        <b-row align-h="center">
          <b-col
            class="pt-5"
            lg="8"
            xl="9"
          >
            <h1 v-html="$t('merci.care.title')"></h1>
            <p v-html="$t('merci.care.desc')"></p>
            <ul class="list-unstyled list-inline text-center">
              <li
                v-for="btn in ['Diaspora', 'Twitter', 'Facebook']"
                :key="btn"
                class="list-inline-item"
              >
                <a
                  :class="`btn-${btn.toLowerCase()}`"
                  target="_blank"
                  rel="nofollow"
                  :href="share[btn.toLowerCase()]"
                  :title="`${$t('merci.care.shareOn')} ${btn}`"
                >
                  <i
                    :class="`fab fa-${
                      btn.toLowerCase().replace('book', 'book-f')
                    } fa-lg fa-shape-square fa-white`"
                  ></i>
                  <span
                    class="sr-only"
                    v-html="btn"
                  ></span>
                </a>
              </li>
            </ul>
            <div
              v-if="$t('lang') === 'fr'"
            >
              <iframe
                border="0"
                class="border-0"
                height="640"
                width="100%"
                src="https://framaforms.org/livre-dor-temoignages-1684758996"
              ></iframe>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <section id="infos">
      <b-container class="ombre">
        <b-row align-h="center">
          <b-col
            class="pt-5"
            lg="8"
            xl="9"
          >
            <h1 v-html="$t('merci.info.title')"></h1>
            <div v-html="$t('newsletter.md')"></div>

            <div class="clearfix mb-2">
              <b-form
                method="post"
                name="subscribeform"
                action="https://listmonk.framasoft.org/subscription/form"
                class="col-md-10 fb-o3 mx-auto my-4 px-4 py-3 rounded"
              >
                <b-input-group class="mb-3">
                  <b-input-group-prepend>
                    <!-- Only decorative-->
                    <b-button
                      aria-hidden="true"
                      class="disabled"
                    >
                      <i class="far fa-envelope fc-g1 fa-fw"></i>
                    </b-button>
                    <label
                      class="sr-only"
                      for="nl-email"
                      v-html="$t('newsletter.your-email')"
                    ></label>
                  </b-input-group-prepend>
                  <b-form-input
                    id="nl-email"
                    v-model="email"
                    name="email"
                    type="email"
                    size="40"
                    :title="$t('newsletter.type-your-email')"
                    :placeholder="$t('newsletter.your-email')"
                  />
                </b-input-group>
                <div class="text-right">
                  <!-- <Unsubscribe :email="email" /> -->
                  <b-button
                    variant="warning"
                    name="subscribe"
                    type="submit"
                    value="subscribe"
                  >
                    <i class="fas fa-bell fc-f1"></i>
                    <span v-html="$t('newsletter.subscribe')"></span>
                  </b-button>
                </div>
                <div style="display: none">
                  <input
                    id="c9c93"
                    checked
                    name="l"
                    type="checkbox"
                    value="c9c93baf-1117-44f4-9e32-a37ed4a97f58"
                  />
                </div>
              </b-form>
            </div>
            <p v-html="$t('merci.info.report', { link: 'https://framadrive.org/s/rX8L6bBDCy97DSw' })"></p>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </main>
</template>

<script>
// import Unsubscribe from '../components/Unsubscribe.vue';

export default {
  components: {
    // Unsubscribe,
  },

  data() {
    const diaspora = [
      'https://share.diasporafoundation.org/?',
      `title=${encodeURIComponent(this.$t('merci.care.share'))}`,
      `&amp;url=${this.$t('link.soutenir')}`,
    ];
    const facebook = [
      'https://www.facebook.com/sharer/sharer.php?',
      `u=${encodeURIComponent(this.$t('link.soutenir'))}`,
      `&title=${encodeURIComponent(this.$t('merci.care.shareTitle'))}`,
      `&description=${encodeURIComponent(this.$t('merci.care.share'))}`,
    ];
    const twitter = [
      'https://twitter.com/intent/tweet?',
      `text=${encodeURIComponent(`${this.$t('merci.care.share')} ${this.$t('link.soutenir')}`)}`,
    ];
    const active = this.cookie('r', 'f-wallpaper') || 1;

    return {
      active,
      email: '',
      share: {
        diaspora: diaspora.join(''),
        facebook: facebook.join(''),
        twitter: twitter.join(''),
      },
    };
  },
};
</script>

<style lang="scss">
#merci {
  .ombre {
    background: var(--f-g2);
    border-radius: 1.75rem;
    box-shadow: none;
    padding: 3rem;
    position: relative;
    margin: 6rem auto;
    z-index: 1;
  }

  section {
    padding: .1px; // why, god, why !?

    h1 {
      border-radius: 0.5rem;
      background: var(--f-o6);
      color: #fff;
      font-family: "Tovari Sans", sans-serif;
      font-size: 3rem;
      font-weight: bold;
      line-height: 2.75rem;
      padding: 1.25rem 1.75rem 0.5rem;
      margin: -8rem auto 3rem;
      max-width: 400px;
      position: relative;
      text-align: center;
      text-transform: uppercase;
      text-shadow: none;
      transform: rotate(-2deg);
    }

    img {
      border-radius: .5rem;
      display: block;
      max-width: 600px;
      margin: 1rem auto;
      width: 100%;
    }
  }

  .btn-diaspora, .btn-twitter, .btn-facebook, .btn-mastodon {
    height: max-content;
    display: inline-block;
  }

  .btn-diaspora .fab { color: #464646; }
  .btn-twitter .fab { color: #1da1f2; }
  .btn-facebook .fab { color: #1877f2; }
  .btn-mastodon .fab { color: #3088d4; }
}

#thank-you {
  position: relative;

  h1 {
    margin: -6rem auto 2rem !important;
  }

  &::after {
    background: url('../../public/img/fr/thank-you.png') top center no-repeat;
    background-size: cover;
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    filter: blur(40px);
    z-index: 0;
  }
}

#header {
  position: relative;
  z-index: 1;
}

#share {
  position: relative;
  background: var(--f-g4);
}

#infos {
  background: url('../../public/img/bg-sky.jpg') top center no-repeat;
}
</style>
